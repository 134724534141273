
import Routes from "@/Routes";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppEvents from "@/AppEvents";
import AuthStore from "@/store/AuthStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PasswordResetRequest from "@/models/PasswordResetRequest";
import InviteStore from "@/store/InviteStore";
import InviteRegisterRequest from "@/models/InviteRegisterRequest";
import { RouteConfig } from "vue-router";

@Component({
  components: {
    LoadingIndicator,
  },
})
export default class InviteRegisterPage extends Vue {
  loading = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  errorMessage = "";
  firstName = "";
  lastName = "";
  password = "";
  confirmPassword = "";
  registerSuccess = false;
  inviteTokenIsValid = false;
  validatingToken = true;

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => 
        !!v || 
        this.$t('generic.validation.required'),
      email: (v: any) => 
        /.+@.+/.test(v) || 
        this.$t('generic.validation.validEmail'),
      passwordMinLength: (v: any) =>
        v.length >= 10 || 
        this.$t('generic.validation.passwordMinLength', { number: 10 }),
      passwordContainsUppercase: (v: any) => 
        /[A-Z]/.test(v) ||
        this.$t('generic.validation.passwordContainsUppercase'),
      passwordContainsLowercase: (v: any) => 
        /[a-z]/.test(v) ||
        this.$t('generic.validation.passwordContainsLowercase'),
      passwordContainsNumber: (v: any) => 
        /[0-9]/.test(v) || 
        this.$t('generic.validation.passwordContainsNumber'),
      confirmPassword: (v: any) => 
        this.password === this.confirmPassword ||
        this.$t('generic.validation.confirmPassword')
    }
  }

  get inviteToken(): string | null {
    return this.$route.query.t 
      ? this.$route.query.t.toString()
      : null;
  }

  get signinRoute(): RouteConfig {
    return Routes.signin;
  }

  async created() {
    await this.validateInviteToken();
  }

  async validateInviteToken() {
    this.validatingToken = true;

    if (this.inviteToken) {
      this.inviteTokenIsValid = await InviteStore.validateToken(this.inviteToken);
    }

    this.validatingToken = false;
  }

  async onRegisterClick() {
    if (!this.inviteToken || this.validatingToken) {
      return;
    }
    this.loading = true;

    const inviteRegisterRequest = new InviteRegisterRequest(this.firstName, this.lastName, this.inviteToken, this.password, this.confirmPassword);
    const success = await AuthStore.registerWithInvite(inviteRegisterRequest);
    if (success) this.registerSuccess = true;
    
    this.loading = false;
  }

  onGoToDashboardClick() {
    // this.$router.push({ name: this.dashboardRoute.name })
  }
}
