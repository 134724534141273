export default class InviteRegisterRequest {
  firstName: string;
  lastName: string;
  inviteToken: string;
  password: string;
  confirmPassword: string;

  constructor(firstName: string, lastName: string, inviteToken: string, password: string, confirmPassword: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.inviteToken = inviteToken;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }
}
