import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store';
import UserInvite from '@/models/UserInvite';
import ApiService from '@/services/ApiService';
import NotificationStore from './NotificationStore';
import SnackbarNotification, { SnackbarNotificationType } from '@/models/SnackbarNotification';
import CreateInviteByCodeRequest from '@/models/CreateInviteByCodeRequest';
import CreateInviteByCodeResponse from '@/models/CreateInviteByCodeResponse';

export interface IInviteStoreState {
}

// Hack due to issues
// https://github.com/championswimmer/vuex-module-decorators/issues/131
// https://github.com/championswimmer/vuex-module-decorators/issues/189

const name = 'invite';
if (store && store.state[name]) {
  try{
    store.unregisterModule(name);
  } catch (e) {
    console.warn("Unregister store module workaround error, ignoring ...")
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name
})
export class InviteStoreModule extends VuexModule implements IInviteStoreState {
  static baseURL = "/api/invite";

  static handleError(err: any) {
    const errString = err.toString();
    NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, errString));
    console.error(err);
  }

  @Action
  public async validateToken(inviteToken: string): Promise<boolean> {
    const path = InviteStoreModule.baseURL + "/validateToken/" + inviteToken;
    try {
      const response = await ApiService.get(path);
      if (response.status === 200) return true;
      return false;
    } catch (err) {
      InviteStoreModule.handleError("Invitation token is not valid, has expired or was already used");
      return false;
    }
  }

  @Action
  public async validateCode(code: string): Promise<boolean> {
    const path = InviteStoreModule.baseURL + "/validateCode/" + code;
    try {
      const response = await ApiService.get(path);
      if (response.status === 200) return true;
      return false;
    } catch (err) {
      InviteStoreModule.handleError("Invitation code is not valid, has expired or was already used");
      return false;
    }
  }

  @Action
  public async createInviteByCode(request: CreateInviteByCodeRequest): Promise<CreateInviteByCodeResponse | null> {
    const path = InviteStoreModule.baseURL + "/createInviteByCode";
    try {
      const response = await ApiService.post<CreateInviteByCodeResponse>(path, request);
      return new CreateInviteByCodeResponse(response.data.emailSent);
    } catch (err) {
      InviteStoreModule.handleError("Error when processing the request. Please check if the invitation code is correct and email is valid.");
      return null;
    }
  }
}

const InviteStore = getModule(InviteStoreModule);
export default InviteStore;